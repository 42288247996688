.pro-sidebar > .pro-sidebar-inner {
  background-color: var(--chakra-colors-blackAlpha-800);
  color: white;
  height: 100vh;
}

.pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout .pro-sidebar-header {
  padding-top: 3rem;
  padding-left: 3rem;
  padding-right: 3rem;
  height: min-content;
  border-bottom: 0;

  @media (max-height: 720px) {
    padding-top: 1rem;
  }
}
.sidebar-header__logo {
  object-fit: contain;
  margin-bottom: 3rem;
  width: 100%;
  height: 50px;
  transition: all 0.2s ease-in-out;

  @media (max-height: 720px) {
    margin-bottom: 1rem;
  }
}

.pro-sidebar .pro-menu.shaped .pro-menu-item > .pro-inner-item > .pro-icon-wrapper {
  background-color: inherit;
}

.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item {
  padding: 8px 20px 8px 20px;
  font-size: 14px;
} 

.pro-sidebar .pro-menu .pro-menu-item:hover {
  background-color: var(--chakra-colors-blackAlpha-300);
  transition: all 0.2s ease-in-out;
}

.pro-sidebar .pro-menu > ul > .pro-sub-menu > .pro-inner-list-item {
  background-color: var(--chakra-colors-blackAlpha-700) !important;
}

.sidebar-item__active {
  background-color: var(--chakra-colors-blackAlpha-600);
}

.pro-sidebar.collapsed .pro-menu > ul > .pro-menu-item.pro-sub-menu > .pro-inner-list-item > .popper-inner {
  background-color: var(--chakra-colors-blackAlpha-700);
}

.pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout .pro-sidebar-footer {
  border: 0;
}





