.container {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  max-width: 100%;
}

.container-left {
  background-color: var(--chakra-colors-blackAlpha-800) !important;
  position: absolute;
  top: 0;
  right: 50%;
  left: 0;
  bottom: 0;
  background-color: white;
  
  @media only screen and (max-width: 600px) {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    height: 80px;
  }
}

.container-left__content {
  top: calc(50% - 40px);
  right: 0;
  left: 0;
  bottom: 0;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  @media only screen and (max-width: 600px) {
    top: 24px;
  }
}

.container-left__content-logo {
  max-height: 120px;
  width: auto;
  
  @media only screen and (max-width: 600px) {
    height: 32px;
    width: auto;
  }
}

.container-left__content-disclaimer {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 48px;
  align-items: center;

  @media only screen and (max-width: 600px) {
    display: none;
  }
}

.container-left__content-disclaimer-label {
  font-size: 12px;
  color: white;
  line-height: 1;
}

.container-left__content-disclaimer-logo {
  height: 22px;
  width: auto;
  cursor: pointer;
}

.container-right {
  position: absolute;
  display: flex;
  top: 0;
  right: 0;
  left: 50%;
  bottom: 0;
  background-color:#fff;
  max-width: 100%;

  @media only screen and (max-width: 600px) {
    position: absolute;
    top: 80px;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: white;
  }
}