.header {
  width: 100%;
  height: 3rem;
  background-color: #f9fafc;
  padding-top: 0rem;
  position: fixed;
  margin-left: 17rem;
  z-index: 100;

  transition: width, left, right, 0.3s;
    transition-duration: 0s, 0s, 0s, 0.3s;
    transition-timing-function: ease, ease, ease, ease;
    transition-delay: 0s, 0s, 0s, 0s;
    transition-property: width, left, right, all;
}

.collapsed {
  width: 100%;
  height: 3rem;
  background-color: #f9fafc;
  padding-top: 0rem;
  position: fixed;
  margin-left: 7rem;
  transition: width, left, right, 0.3s;
    transition-duration: 0s, 0s, 0s, 0.3s;
    transition-timing-function: ease, ease, ease, ease;
    transition-delay: 0s, 0s, 0s, 0s;
    transition-property: width, left, right, all;
}

.container {
  display: flex;
  flex-direction: row;
  background-color: #f9fafc;
  height: 100vh;
}

.buttonGroup {
  text-align: right;
  margin-right: 17rem;
}

.badge {
  background-color: #1eb7ff;
  padding: 0.25rem 0.42rem;
  border-radius: 25px;
  margin-bottom: 1rem;
  font-size: xx-small;
}

.badgeDisabled {
  background-color: #adb7c3;
  padding: 0.25rem 0.42rem;
  border-radius: 25px;
  margin-bottom: 1rem;
  font-size: xx-small;
}

.outlet {
  transition: width, left, right, 0.3s;
    transition-duration: 0s, 0s, 0s, 0.3s;
    transition-timing-function: ease, ease, ease, ease;
    transition-delay: 0s, 0s, 0s, 0s;
    transition-property: width, left, right, all;
}